var cdnHostDomainVersionfolder = '0.91';
//var cdnHostDomain = 'https://static-preprod.elxform.com/0.91';
//var cdnHostDomain = 'https://static-local.elxform.test/0.91';

//ABTestGenerator -----------------
var versions = [
    {  id : 1, name : 'version_1', percentage : 0 },
    {  id : 2, name : 'version_2', percentage : 0 },
    {  id : 3, name : 'version_3', percentage : 0 },
    {  id : 4, name : 'version_4', percentage : 0 },
    {  id : 5, name : 'version_5', percentage : 100 },
    {  id : 51, name : 'version_51', percentage : 0 }
];

var currentABVersion = ABTestGenerator(versions);

var ABTestVersion = currentABVersion.name;
var ABTestVersionID = currentABVersion.id;

var qualificationTemplateVersion = ABTestVersion;
var templateGeneraleVersion = ABTestVersion;
//ABTestGenerator -----------------

function spinnerOverlay() {
    var overlay = document.createElement('div');
    overlay.className ='white-overlay';
    document.body.appendChild(overlay);

    var spinnercontainer = document.createElement('div');
    spinnercontainer.className ='spinner-container';
    overlay.appendChild(spinnercontainer);

    var spinner = document.createElement('span');
    spinner.className ='fa fa-spinner fa-pulse';
    spinnercontainer.appendChild(spinner);
}

function getUrlParams( prop ) {
    var params = {};

    try {
        var search = decodeURIComponent(window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ));
    }
    catch(err) {
        // in case of : Windows-1252 encoding can crash decodeURIComponent
        var search = unescape(window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ));
    }

    var definitions = search.split( '&' );

    definitions.forEach( function( val, key ) {
        var parts = val.split( '=', 2 );
        params[ parts[ 0 ] ] = parts[ 1 ];
    } );

    return ( prop && prop in params ) ? params[ prop ] : !prop ? params : null;
}

function loadScript(sScriptSrc) {
    var oHead = document.getElementsByTagName('head')[0];
    var oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    oScript.src = sScriptSrc;
    oScript.async = false;
    oHead.appendChild(oScript);
}

function loadStylesheet(sStylesheetSrc) {
    var oHead = document.getElementsByTagName('link')[document.getElementsByTagName('link').length-1];
    var oScript = document.createElement('link');
    oScript.type = 'text/css';
    oScript.rel = 'stylesheet';
    oScript.href = sStylesheetSrc;
    oHead.parentNode.insertBefore(oScript,oHead);
}


function loadAllScripts() {
    for (var i = 0; i < aScriptPath.length; i++) {
        loadScript(aScriptPath[i]+"?"+r);
    }
}

function loadAllStylesheets() {
    for (var i = 0; i < aStylesheetPath.length; i++) {
        loadStylesheet(aStylesheetPath[i]+"?"+r);
    }
}

function defer(method) {
    if (window.jQuery)
        method();
    else
        setTimeout(function() { defer(method) }, 50);
}

function deferJsView(method) {
    if ($.templates)
        method();
    else
        setTimeout(function() { deferJsView(method) }, 50);
}

var r = Math.floor(Math.random()*90000001 + 10000000);

window.addEventListener("load", function(){
    console.log('LOADED!!!');
});

var aStylesheetPath = [
    cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/css/app.css"
];

var aScriptPath = [];


console.log(window.location.href);

// aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/config.js");
aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/vendors/app.js");
aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/app.js");

var urlHasPid = false;

if(getUrlParams('pid')) {
    urlHasPid =true;

    document.addEventListener('DOMContentLoaded',function(event){
        spinnerOverlay();
    });
}

if(urlHasPid) {
    aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/eg.smartoffer-init.js");
} else if( typeof pageType != 'undefined' && pageType == 1 /* Ranking */ ){
    aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/eg.rankingpage-init.js");
} else {

    //Start load script css new form version A\B Testing
    //aStylesheetPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/css/standalone/form"+formType+".css");
    aStylesheetPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/css/standalone/form_"+ABTestVersion+".css");
    //Stop load script css new form version A\B Testing

    aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/jquery.validate.local/messages_fr.js");
    aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/static/js/standalone/moment.min.js");

    aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/errors-display/classic-error-display.js");
    aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/form-display/form"+ABTestVersionID+".js");

    aScriptPath.push(cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/eg.form.js");

}

if( typeof instantWin != 'undefined' && instantWin == 1) {
    aScriptPath.push("js/openInstantWin.js"); // game specific file
}

loadAllStylesheets();

// if (!window.jQuery) {
//     loadScript( cdnHostDomain + '/' + cdnHostDomainVersionfolder + "/public/js/standalone/jquery-1.8.3.min.js");
// }

defer(function () {

    var templateQualification = 'templateQualification_generic';

    if(typeof(qualificationTemplateVersion) != "undefined") {
        templateQualification = 'templateQualification_'+qualificationTemplateVersion;
    }

    if( typeof pageType != 'undefined' && pageType == 1 ){
        loadAllScripts();
    } else {

        $.when($.get(cdnHostDomain + '/' + cdnHostDomainVersionfolder + '/html/'+templateQualification+'.html'))
            .done(function(tmplData) {
                $('body').append(tmplData);
                $.when($.get(cdnHostDomain + '/' + cdnHostDomainVersionfolder + '/html/templateGeneral.html'))
                    .done(function(tmplData) {
                        $('body').append(tmplData);
                        
                        console.log( templateGeneraleVersion );

                        if(typeof(templateGeneraleVersion) != "undefined" ){
                            var templateGeneral = 'templateGeneral_'+templateGeneraleVersion;
                            $.when($.get(cdnHostDomain + '/' + cdnHostDomainVersionfolder + '/html/'+templateGeneral+'.html'))
                                .done(function(tmplData) {
                                    $('body').append(tmplData);
                                });    
                        }
                    
                        if(!urlHasPid) {

                            //Start load script css new form version A\B Testing
                            //$.when($.get(cdnHostDomain + '/' + cdnHostDomainVersionfolder + '/html/templateForm'+formType+'.html'))
                            $.when($.get(cdnHostDomain + '/' + cdnHostDomainVersionfolder + '/html/templateForm_'+ABTestVersion+'.html'))
                            //Stop load script css new form version A\B Testing

                                .done(function(tmplData) {
                                    $('body').append(tmplData);
                                    deferTmpl(function () {
                                        loadAllScripts();
                                    });
                                });
                        } else {
                            loadAllScripts();
                        }

                    });

            });
    
    }

});

function deferTmpl(method) {
    if (
        $('#endRedirectPageTmpl').html() != ''
        && $('#endPageTmpl').html() != ''
        && $('#formTmpl').html() != ''
        && $('#coregContentTmpl').html() != ''
        && $('#coregCampaignTmplNew').html() != ''
        && $('#classicEndPageTmpl').html() != ''
        && $('#sponsorModalListItemTmpl').html() != ''
        && $('#partnerModalListItemTmpl').html() != ''
        && $('#modalContentCoregTmpl') != ''
        && $('#qualificationsContentTmpl').html() != ''
        && $('#qualificationsHeaderTmpl').html() != ''
        && $('#cookiesTmpl').html() != ''
        && $('#legalNoticeTmpl').html() != ''
        && $('#footerLinksTmpl').html() != ''
        && $('#elixisPartnerListTmpl').html() != ''
        && $('#privacyPolicyTmpl').html() != ''
        && $('#trackerPrivacyPolicyTmpl').html() != ''
        && $('#sponsorContentTmpl').html() != ''
        && $('#sponsorItemTmpl').html() != ''
        && $('#partnerContentTmpl').html() != ''
        && $('#partnerItemTmpl').html() != ''
        && $('#coregHeaderTmpl').html() != ''
        && $('#footerTmpl').html() != ''
        && $('#layoutTmpl').html() != ''
    )
        method();
    else
        setTimeout(function() { deferTmpl(method) }, 50);
}

//Return random version for A/B testing
function ABTestGenerator(versions)
{

    var result = null;
    var randWeight = Math.round(Math.random() * 100);
    var validAbVersion = false;

    for (let version of versions ) {
        if( version.name == getUrlParams('abversion') ){
            validAbVersion = true;
            result = {name : version.name, id : version.id};
            break;
        }
    }

    if( !validAbVersion ){
        for (let version of versions ) {
            if( randWeight < version.percentage ){
                result = {name : version.name, id : version.id};
                break;
            }
            randWeight -= version.percentage;
        }
    }
    
    return result;

}
